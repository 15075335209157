import {
	SHOP_MODIFIER_START,
	SHOP_MODIFIER_SUCCESS,
	SHOP_MODIFIER_ERROR
} from '../../../constants/index';
import getAllShopModifierQuery from './getAllShopModifier.graphql';

export function getAllShopModifier() {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: SHOP_MODIFIER_START,
		});
		try {

			const { data: { getAllShopModifier } } = await client.query({
				query: getAllShopModifierQuery,
				fetchPolicy: 'network-only'
			});

			if (getAllShopModifier && getAllShopModifier.status === 200) {
				dispatch({
					type: SHOP_MODIFIER_SUCCESS,
					payload: {
						modifier: getAllShopModifier.results
					}
				});

			} else {
				dispatch({
					type: SHOP_MODIFIER_ERROR,
				});
			}

		} catch (error) {
			dispatch({
				type: SHOP_MODIFIER_ERROR,
			});
		}
	}
}