import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './HomeSlider.css';
import { Container } from 'react-bootstrap';
import messages from '../../../locale/messages';
import cx from 'classnames';
//Swiper
//import Swiper from 'react-id-swiper';
// React-Slick
import Slider from "react-slick";
import HomeItem from '../HomeItem';

//Images
import slider1 from '../../../../public/SiteImages/food-sample-image.png';
import LeftArrowIcon from '../../../../public/SiteImages/leftSliderarrow.png';
import RightArrowIcon from '../../../../public/SiteImages/righSliderarrow.png';

const nextArrowStyle = {
    right: '-32px',
    background: '#fff', color: '#484848', zIndex: '5', width: '52px', height: '52px', top: '40%',
    fontSize: '40px', cursor: 'pointer', borderRadius: '50%', textAlign: 'center',
    border: '2px solid transparent', boxShadow: '0px 0px 21px 3px #eeedef'
};

const prevArrowStyle = {
    left: '-27px',
    background: '#fff', color: '#484848', zIndex: '5', width: '52px', height: '52px', top: '40%',
    fontSize: '40px', cursor: 'pointer', borderRadius: '50%', textAlign: 'center',
    border: '2px solid transparent', boxShadow: '0px 0px 21px 3px #eeedef'
};

const nextArrowMobileStyle = {
    display: 'none', right: '10px',
    background: '#fff', color: '#484848', zIndex: '5', width: '56px', height: '56px', top: '38%',
    fontSize: '40px', cursor: 'pointer', borderRadius: '50%', textAlign: 'center',
    border: '2px solid transparent', boxShadow: '0px 0px 4px 0px #484848'
};

const prevArrowMobileStyle = {
    display: 'none', left: '10px',
    background: '#fff', color: '#484848', zIndex: '5', width: '56px', height: '56px', top: '38%',
    fontSize: '40px', cursor: 'pointer', borderRadius: '50%', textAlign: 'center',
    border: '2px solid transparent', boxShadow: '0px 0px 4px 0px #484848'
};


function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={nextArrowStyle}
            onClick={onClick}
        >
            <img src={RightArrowIcon} className={s.arrowImg} alt="right-arrow" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={prevArrowStyle}
            onClick={onClick}
        >
            <img src={LeftArrowIcon} className={s.arrowImg} alt="right-arrow" />
        </div>
    );
}

function MobileNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={nextArrowMobileStyle}
            onClick={onClick}
        >
        </div>
    );
}

function MobilePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={prevArrowMobileStyle}
            onClick={onClick}
        >
        </div>
    );
}

class HomeSlider extends React.Component {

    static defaultProps = {
        data: []
    }

    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props
        
        var settings = {
            dots: false,
            infinite: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        nextArrow: <MobileNextArrow />,
                        prevArrow: <MobilePrevArrow />,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 0,
                        swipe: true,
                        swipeToSlide: true,
                        touchMove: true
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        nextArrow: <MobileNextArrow />,
                        prevArrow: <MobilePrevArrow />,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 0,
                        swipe: true,
                        swipeToSlide: true,
                        touchMove: true,
                        centerMode: true
                    }
                }]
        };

        return (
            <Container>
                <div className={s.root}>
                    <div className={s.paddingTopBottom}>
                        <div className={cx(s.sliderMain, 'sliderCommon')}>
                            <Slider {...settings}>
                                {
                                    data && data.result && data.result.length > 0 && data.result.map((item, index) => {
                                            return (
                                                    <HomeItem key={index} categoryName={item.categoryName} 
                                                    categoryImage={item.categoryImage} />
                                            ) 
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}


export default withStyles(s)(HomeSlider);
