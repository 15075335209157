import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { closeForgotPasswordModal } from '../../modalActions';

export function sendForgotPasswordLink(email) {

    return async (dispatch, getState, { client }) => {

        dispatch(closeForgotPasswordModal());

        try {
            let mutation = gql`
                mutation sendForgotPasswordLink($email: String!) {
                    sendForgotPasswordLink (email: $email) {
                        status
                        errorMessage
                    }
                }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: { email }
            });

            if (data && data.sendForgotPasswordLink) {
                if (data.sendForgotPasswordLink.status === 200) {
                    toastr.success("Reset Link Sent to your email", "Reset link email is on its way to your inbox");
                    return true;
                } else {
                    toastr.error("Send Reset Link Failed", data.sendForgotPasswordLink.errorMessage);
                    return false;
                }
            } else {
                toastr.error("Send Reset Link Failed", "Something went wrong, please try again later");
                return false;
            }
        } catch (error) {
            toastr.error("Send Reset Link Failed", "Something went wrong, please try again later " + error);
            return false;
        }
    };
}