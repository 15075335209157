import gql from 'graphql-tag';
import {
    SET_USER_DATA_START,
    SET_USER_DATA_SUCCESS,
    SET_USER_DATA_ERROR
} from '../../../constants';

const query = gql`
query userAccount{
    userAccount {
      id
      email
      phoneNumber
      phoneDialCode
      phoneCountryCode
      userStatus
      isActive
      isBan
      userType
      createdAt
      activeStatus
      overallRating
      deletedAt
      profile {
        userId
        firstName
        lastName
        preferredLanguage
        preferredCurrency
        country
        city
        state
        zipcode
        profileId
        lat
        lng
        picture
        shopName
        description
        priceRangeId
        address
        deliveryMode
        deliveryType
      }
      shopCategory {
        categoryId
        categoryName
      }
    }
  }
`;

export function loadAccount() {

    return async (dispatch, getState, { client }) => {

        dispatch({
            type: SET_USER_DATA_START,
        });

        try {

            const { data } = await client.query({
                query,
                fetchPolicy: 'network-only'
            });

            if (data && data.userAccount) {
                let userAccountState = getState().account.data

                let updatedProfileData = { ...userAccountState, ...data.userAccount }
                
                dispatch({
                    type: SET_USER_DATA_SUCCESS,
                    updatedProfileData
                });

            };

        } catch (error) {

            dispatch({
                type: SET_USER_DATA_ERROR,
            });

        };

    };
};
