import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EatsMobileApp.css';
import cx from 'classnames';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
/*Images*/
import AndroidIcon from '../../../../public/SiteImages/android.png';
import IosIcon from '../../../../public/SiteImages/ios.png';
import MobileBg from '../../../../public/SiteImages/mobile-bg.svg'
import MobileScreenApp from '../../../../public/SiteImages/mob-only.png';
import { api } from '../../../config';

class EatsMobileApp extends React.Component {

    render() {

        const { data } = this.props;
        let homeSettings = {};

        data && data.homePageData.map((item) => {
            homeSettings[item.name] = item.value
        });

        return (
            <div className={s.root}>
                {/* <div className={s.mobileBg}
                                    style={{ backgroundImage: `url(${MobileBg})` }}></div> */}
                <Container className={cx(s.bannerLayoutContainer)} >
                    <Row className={s.displayFlex}>
                        <Col xl={6} md={6} sm={12} xs={12}>
                            <div className={s.signUp}>
                                <div className={s.mobileBgSection}>
                                    <img src={MobileBg} className={s.phoneBg} />
                                </div>
                                <div className={s.phoneImgSection}>
                                    <img src={api.apiEndpoint + "/images/homepage/" + homeSettings.safetyGridImage3} className={s.phoneImg} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={6} sm={12} xs={12} className={cx(s.spaceTop6)}>
                            <div className={cx(s.signUp, s.space5)}>
                                <h3>
                                    {/* Download <span className={s.siteColor}>Wooberly Eats</span> Mobile App */}
                                    <span className={s.siteColor}>{homeSettings.safetyGridTitle1}</span>
                                </h3>
                                <p>
                                    {homeSettings.safetyGridContent1}
                                </p>
                            </div>
                            <div className={cx(s.spaceTop4)}>
                                <div className={cx(s.displayInlineBlock, s.paddingRight)}>
                                    <a href={homeSettings.safetyGridLink1} target="_blank" className={s.btn}>
                                        <img src={api.apiEndpoint + "/images/homepage/" + homeSettings.safetyGridImage1} className={s.appImg} />
                                        <span><FormattedMessage {...messages.androidLabel} /></span>
                                    </a>
                                </div>
                                <div className={cx(s.displayInlineBlock, s.paddingLeft)}>
                                    <a href={homeSettings.safetyGridLink2} target="_blank" className={s.btn}>
                                        <img src={api.apiEndpoint + "/images/homepage/" + homeSettings.safetyGridImage2} className={s.appImg} />
                                        <span><FormattedMessage {...messages.iphoneLabel} /></span>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withStyles(s)(EatsMobileApp);